.result-bar {
    display: flex;
    justify-content: center;

    p {
        text-align: left !important;
        margin: 0;
        font-size: medium !important;
    }

    button {
        border: none;
        background: none;
        padding: 0;
        margin-left: 2%;

        img {
            transform: scale(0.85);
        }
    }
}
