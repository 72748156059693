@use "values";

footer {
    background-color: values.$dark-grey;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100px;

    p:first-child {
        font-weight: bold;
    }

    p {
        text-align: center;
        margin: 1px;
        font-size: 12px;
        color: white;
    }
}
