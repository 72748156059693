@use "values";

.logo {
    margin-top: 5px;
    width: 50px;
    height: 50px;
}

.form-content,
.form-question-response {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.back-button {
    color: white;
    font-weight: bold;
    background-color: values.$dark-grey;
    border-style: none;
    width: 70%;
    height: 45px;
    font-size: large;
    margin-top: 5%;
    margin-bottom: 1%;
}

.question-area {
    width: 100%;
    text-align: center;

    h1 {
        margin-top: 0;
        margin-bottom: 5%;
    }

    p {
        margin: 0;
    }

    .section-indicator {
        font-size: 0.85em;
        color: #484141;
    }

    .question {
        margin-top: 5%;
        padding: 0 5% 0 5%;
    }
}

.note {
    text-align: center;
    font-size: small;
    margin: 15px 15% 0 15%;
}

@media only screen and (min-width: 500px) {
    .form-question-response {
        width: 100%;
    }

    .logo {
        visibility: hidden;
        height: 0;
    }

    .back-button {
        width: 250px;
    }

    h1 {
        background: values.$grey;
        padding: 20px 0 20px 0;
        margin-bottom: 2%;
    }

    .question-area {
        .question {
            font-weight: bold;
            padding: 0;
            margin-top: 2%;
        }

        h1 {
            margin-bottom: 1%;
        }
    }

    .note {
        visibility: hidden;
    }

    .back-button {
        margin-top: 1%;
        margin-bottom: 1%;
    }
}
