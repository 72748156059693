@use "values";

.results-desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    max-width: 85% !important;
    margin: 0 auto 0 auto;

    .main-header {
        background: white;
        text-align: center;
        border-radius: 15px;
        width: 98%;
        margin: 1% 0 1% 0;
        font-size: 1.6em;
        padding: 0.67em 0 0.67em 0;
    }

    .info-button {
        background: values.$red;
        border: none;
        color: white;
        font-weight: bold;
        font-size: large;
        height: 45px;
        padding: 0 10px 0 10px;
        margin-top: 12%;
    }

    .info-circle {
        margin-right: 5px;
    }

    .feedback-container {
        display: flex;
        justify-content: space-between;
        gap: 2%;
        flex-wrap: wrap;
        min-height: 50vh;
    }

    .individual-results {
        background: white;
        flex: 1 1 0;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1em;
        min-height: 100%;

        h1 {
            background: none;
            border: none;
            border-radius: 0;
            padding: 0;
            font-weight: normal;
            font-size: 1.3em;
            height: 2em;
            text-align: center;
            margin-bottom: 2%;
        }

        h3 {
            font-size: 1em;
            margin-top: 3%;
        }

        p {
            font-size: 0.8em;
            margin-bottom: 3%;
        }

        img {
            width: 65%;
        }
    }

    .results-desktop-note {
        margin-bottom: 0;
        margin-top: 0.5%;
        font-weight: bold;
    }
}

@media (max-width: 850px) {
    .feedback-container {
        width: 65% !important;
    }

    .individual-results {
        margin-bottom: 10px;
    }

    .main-header {
        width: 68% !important;
    }
}
