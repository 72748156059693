@use "values";

.enrollment-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../backgrounds/all-icons.svg");

    h1 {
        margin: 3% 0 0 0;
        width: 100%;
        text-align: center;
    }

    p {
        text-align: center;
        margin-bottom: 10vh;
    }

    a {
        text-align: center;
    }

    button {
        border-style: solid;
        background-color: white;
        border-width: 2px;
        border-radius: 5px;
        font-size: 1.3em;
        font-weight: bold;
        width: 75%;
        padding: 30px 5px 30px 5px;
        margin: 3vh 0 3vh 0;
    }

    .chosen {
        border-color: values.$red;
        color: white;
        background-color: values.$red;
    }

    .selected {
        animation-duration: 0.5s;
        animation-name: selected;
    }
}

@keyframes selected {
    0%,
    100% {
        background: values.$red;
        color: white;
        border-color: values.$red;
    }
}

@media only screen and (min-width: 500px) {
    .enrollment-status {
        background-image: none;

        button {
            padding: 15px 5px 15px 5px;
        }
    }
}
