@use "values";

.section-intro {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        background-color: values.$grey;
        padding: 20px;
        text-align: center;
        box-sizing: border-box;
        min-width: 100%;
    }

    div {
        background-color: values.$grey;
        padding: 20px;
        margin-top: 10%;
    }

    p {
        text-align: center;
        font-weight: bold;
    }

    p:nth-child(2) {
        margin-top: 50px;
        font-weight: normal;
    }

    button {
        background-color: values.$red;
        color: white;
        font-size: large;
        font-weight: bold;
        width: 60%;
        height: 45px;
        border: 0;
        margin-top: 8%;
    }
}

@media only screen and (min-width: 500px) {
    .section-intro {
        button {
            width: 250px;
        }
    }
}
