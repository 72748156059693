@use "values";

.results-home {
    background: white url("../backgrounds/all-icons.svg");

    .individual-results {
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            text-align: center;
            margin-top: 2%;
            margin-bottom: 0;
            height: 90px;
        }

        .back-button {
            border: none;
            background-color: values.$red;
            height: 45px;
            color: white;
            font-size: large;
            width: 70%;
            font-weight: bold;
            margin-top: 10%;
        }
    }

    .all-results {
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            margin-top: 1%;
            margin-bottom: 0;
        }

        .note {
            text-align: center;
            margin: 1% 10% 0 10%;
            font-size: 18px;
        }

        .info-button {
            background: values.$red;
            border: none;
            color: white;
            font-weight: bold;
            font-size: large;
            height: 45px;
            margin-top: 10%;
            margin-bottom: 5%;
            padding: 0 10px 0 10px;
        }

        .info-circle {
            margin-right: 5px;
        }

        .result-area {
            margin-top: 8%;
            width: 90%;
        }
    }
}

.individual-results {
    h3 {
        text-align: left;
        margin-bottom: 3%;
    }

    p {
        margin-top: 0;

        a {
            color: values.$red;
            text-decoration: none;
            font-weight: bold;
        }

        a:hover {
            text-decoration: underline;
        }
    }

    .feedback-area {
        width: 70%;
    }
}
