@use "values";

.progress-bar {
    display: flex;
    justify-content: space-between;
    max-width: min(40%, 150px);
    margin: 10px auto 0;
}

.progress-bar-circle-container {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
}

.progress-bar-circle {
    position: relative;
    width: min(15px, 4vw);
    height: min(15px, 4vw);
    background: values.$grey;
    border-radius: 50%;
    z-index: 1;
}

.progress-bar-circle-container:last-child {
    flex: 0;
}

.progress-bar-circle-container:not(:last-child):after {
    position: absolute;
    content: "";
    border-bottom: 3px solid values.$grey;
    width: 100%;
    top: calc(62% / 1.65);
    z-index: 0;
}

.current {
    box-sizing: border-box;
    background-color: white;
    border: 1px solid values.$grey;
}

.answered {
    background-color: values.$red !important;
    border: none !important;
}
