@use "values";

.home {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        padding: 20px 0 20px 0;
        text-align: center;
        background-color: values.$grey;
        width: 100%;
    }

    .text {
        padding: 1% 8% 0 8%;

        p:first-child {
            text-align: center;
            margin-bottom: 4%;
        }

        ul {
            padding-left: 5%;
        }

        li {
            margin: 2% 0 2% 0;
        }
    }

    button {
        background-color: values.$red;
        color: white;
        font-size: large;
        font-weight: bold;
        width: 60vw;
        height: 45px;
        border: 0;
        margin-top: 25%;
    }
}

@media only screen and (min-width: 500px) {
    .home {
        button {
            width: 250px;
        }
    }
}
