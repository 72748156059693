@use "values";

header {
    background-color: values.$red;
    color: white;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        height: 70%;
    }

    img {
        max-height: 100%;
    }
}
