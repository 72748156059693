@use "values";

.add-information {
    display: flex;
    flex-direction: column;
    background-image: url("../backgrounds/all-icons.svg");

    h1 {
        text-align: center;
    }

    p {
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        width: 82%;
    }

    form {
        display: flex;
        flex-direction: column;
    }

    label {
        width: 65%;
    }

    label:nth-of-type(-n + 3) {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: large;
        margin: auto;

        input {
            margin-top: 1%;
            margin-bottom: 1%;
            height: 20px;
        }
    }

    label:nth-of-type(n + 4) {
        display: flex;
        flex-direction: row;
        font-size: small;
        margin: 2vh auto 1vh auto;

        input {
            margin-right: 10px;
            margin-left: 0;
        }
    }

    input {
        border-style: solid;
        accent-color: values.$red;
    }

    .text-input {
        padding: 0.5em 1em;
    }

    input:focus {
        outline-color: values.$red;
    }

    button {
        background-color: values.$red;
        color: white;
        font-size: large;
        font-weight: bold;
        width: 70%;
        height: 45px;
        border: 0;
        margin: 5% auto 0;
    }

    button:disabled {
        background: values.$dark-grey;
    }
}

@media only screen and (min-width: 500px) {
    .add-information {
        background-image: none;

        button {
            max-width: 250px;
        }

        p {
            width: 95% !important;
        }

        label:nth-of-type(n + 4) {
            align-items: center;
            font-size: large;

            input {
                margin-right: 10px;
                margin-left: 0;
                width: 25px;
                height: 25px;
            }
        }
    }
}
