@use "values";

.response-area {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 1% 0 0 0;

    li {
        margin: 1% auto;
        width: 75%;
    }

    button {
        border-style: solid;
        border-width: 2px;
        border-radius: 5px;
        background: none;
        width: 100%;
        font-size: 18px;
        padding: 0.5em 0 0.5em;
        text-align: center;
        position: relative;

        img {
            position: absolute;
            left: 8%;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    button:focus-visible {
        outline-color: values.$red;
    }

    .selected {
        animation-duration: 0.5s;
        animation-name: selected;
    }

    .chosen {
        background: values.$red;
        color: white;
        border-color: values.$red;
    }

    .chosen:focus-visible {
        outline-color: values.$dark-grey;
    }
}

@keyframes selected {
    0%,
    100% {
        background: values.$red;
        color: white;
        border-color: values.$red;
    }
}
