@use "values";

* {
    font-family: "Open Sans", sans-serif;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
    min-height: 100vh;
    font-size: 19px;
}

h1 {
    line-height: 1;
    margin-top: 0;
}

main {
    background: white;
    min-height: calc(100vh - 210px);
    padding: 1em 0 2em;
}

body > footer {
    position: sticky;
    top: 100vh;
}

button {
    cursor: pointer;
    user-select: none;
}

@media only screen and (min-width: 500px) {
    #root {
        background: values.$grey url("../backgrounds/all-icons-dark.svg") space;
        font-size: 16px;
    }

    main {
        max-width: 700px;
        margin: 0 auto;
    }
}
